.container {
  min-height: 100vh;
  padding-top: 2.5rem;
  background-color: var(--background-color) /*#232323*/;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .container {
    padding-top: 3.5rem;
  }
}
