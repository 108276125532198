/* Responsive scaling - mobile - default*/
.container {
  background-color: var(--tertiary-background-color); /*#2020209c;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  font-size: 1rem;
  color: var(--text-color);
  background-image: var(--color-gradient);
  width: 100%;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.infoText {
  color: var(--text-color-error);
  text-align: center;
  font-size: 1.2rem;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .container {
    width: 100%;
  }

  .text {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 1.2rem;
    margin: auto;
  }
}
@media (min-width: 992px) {
  .container {
    width: 100%;
  }
}
