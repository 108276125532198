.container {
  position: relative;
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  height: 40rem;
  overflow: hidden;
}

.sliderContainer {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
}

.card {
  position: absolute;
  width: 20rem;
  height: 30rem;
  transition: all 300ms ease-in-out;
  opacity: 0.5;
}

.cardInner {
  width: 100%;
  height: 100%;
  background-color: rgb(7, 11, 26);
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  padding: 1.5rem;
  backdrop-filter: blur(4px);
  overflow-y: auto;
}

.currentCard {
  position: relative;
  width: 25rem;
  height: 35rem;
  transition: all 300ms ease-in-out;
  z-index: 20;
  opacity: 1;
}

.currentCardInner {
  width: 100%;
  height: 100%;
  background-color: rgb(7, 11, 26);
  border-radius: 0.5rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
  padding: 1.5rem;
  overflow-y: auto;
}

.prevCard {
  transform: translateX(-6rem) rotate(-12deg);
}

.nextCard {
  transform: translateX(6rem) rotate(12deg);
}

.cardAnimating {
  transform: scale(0.95);
}

.cardTitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: rgb(255, 255, 255);
}

.currentCardTitle {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: rgb(255, 255, 255);
}

.cardContent {
  margin-top: 1rem;
  font-size: 16px;
  color: rgb(255, 255, 255);
}

/* Image content styles */
.imageContainer {
  margin: 1rem 0;
}

.cardImage {
  width: 100%;
  height: auto;
  border-radius: 0.375rem;
  object-fit: cover;
}

.imageCaption {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #666;
  text-align: center;
}

/* Audio content styles */
.audioContainer {
  margin: 1rem 0;
}

.audioTitle {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.audioPlayer {
  width: 100%;
  margin-top: 0.5rem;
}

/* Video content styles */
.videoContainer {
  margin: 1rem 0;
}

.videoCardInner {
  width: 100%;
  height: 100%;
  background-color: black; /* Background to blend with video */
  border-radius: 0.5rem;
  overflow: hidden; /* Ensures video does not overflow */
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayer {
  width: 100%;
  height: 100%; /* Make video occupy entire space */
  object-fit: cover; /* Ensures it covers the full container */
}

/* Mixed content styles */
.mixedContent > * {
  margin-bottom: 1rem;
}

.mixedContent > *:last-child {
  margin-bottom: 0;
}

/* Error content styles */
.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  color: #ef4444;

  min-height: 8rem;
}

.errorIcon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.errorMessage {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666;
}

/* Navigation buttons */
.navigationButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 9999px;
  padding: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  transition: background-color 200ms;
  z-index: 100;
}

.navigationButton:hover {
  background-color: rgb(243 244 246);
}

.prevButton {
  left: 2rem;
  z-index: 100;
}

.nextButton {
  right: 2rem;
  z-index: 100;
}
