/* LIGHT THEME */
body.light {
  --background-color: #ffffff;
  --secondary-background-color: rgba(255, 255, 255, 0.712); /* containers */
  --tertiary-background-color: #deebf0;
  --background-color-gradient: #ffffffce;
  --secondary-background-color-gradient: rgba(255, 255, 255, 0);
  --background-color-input: #deebf0d8;
  --background-color-active: #d0e3ebd8;
  --text-color: #232323;
  --text-color-error: #aa67bb;
  --extra-text-color: white;
  --chat-win-color: rgba(247, 243, 252, 0.89);
  --frame-hover: rgba(237, 226, 251, 0.89);
  --chatbot-message: rgba(251, 251, 254, 1);
  --user-message: rgba(247, 243, 252, 1);
  --selected-conversation: rgba(251, 251, 254, 1);
  --panel-color: #c6e0eb;
  --scrollbar-color: #4e869ecc;
  --scrollbar-color-hoover: #457a91bd;
  --link: rgb(31, 164, 201);
  --send-button: rgb(255, 255, 255);
  --link2: rgb(87, 184, 230);
  --audio-controls: rgb(31, 164, 201);
  --card-slider: linear-gradient(#ccdff7, #9fbbfd);
  --card-answer: #7ab0fc;
}

/* DARK THEME */
body.dark {
  --background-color: #1c1c1c;
  --secondary-background-color: #2020209c; /* containers */
  --tertiary-background-color: #2020209c;
  --background-color-gradient: #232323ad;
  --secondary-background-color-gradient: rgba(255, 255, 255, 0);
  --background-color-input: #202020bb;
  --background-color-active: #4242429c;
  --text-color: #ffffff;
  --text-color-error: #aa67bb;
  --extra-text-color: black;
  --chat-win-color: rgba(32, 34, 38, 0.9);
  --frame-hover: rgba(36, 38, 42, 0.9);
  --chatbot-message: rgba(49, 57, 68, 1);
  --user-message: rgba(32, 34, 38, 0.9);
  --selected-conversation: rgba(49, 57, 68, 1);
  --panel-color: #202020;
  --scrollbar-color: #2b2b2b;
  --scrollbar-color-hoover: #2b2b2b;
  --link: rgb(30, 94, 110);
  --send-button: #202226;
  --link2: rgba(123, 205, 243, 1);
  --carousel-card: rgb(31, 31, 31);
  --audio-controls: rgb(31, 164, 201);
  --card-slider: linear-gradient(#27374b, #1c2230);
  --card-answer: #334155;
}

/* FONT SIZE */
:root {
  --font-size-small: 0.8rem;
  --font-size-base: 1rem; /* Typically 16px */
  --font-size-large: 1.25rem;
  --font-size-xlarge: 1.5rem;
}

/* BUTTON TRANSFORM */
:root {
  --active-scale: 0.99;
  --active-transition-duration: 0.1s;
}

/* LINEAR GRADIENT */
:root {
  --color-gradient: linear-gradient(
    to left,
    rgb(179, 36, 215) 7.07%,
    rgb(0, 142, 206) 97.81%
  );
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Slide In Animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Using CSS Variables to store animation for reuse */
:root {
  --animation-fadeIn: fadeIn 0.5s ease-in-out forwards;
  --animation-slideIn: slideIn 0.5s ease-out forwards;
}
