.Container {
  width: 100%;
  color: var(--text-color);
  padding-bottom: 60px;
}

.simple_radial {
  background: radial-gradient(
    var(--background-color-gradient) 50%,
    var(--secondary-background-color-gradient) 90%
  );
}

.contentWrapper {
  padding-top: 2rem;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align the items */
  gap: 20px; /* Adjust the gap between elements */
  justify-content: center;
  text-align: center;
}

.text {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 15px; /* Adds some padding around the text */
}

@media (min-width: 768px) {
  .text {
    font-size: 2.3rem;
    padding: 0 20px; /* Adds some padding around the text */
  }
}
