.box {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 3rem);
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}

.firstBox {
  position: absolute;
  z-index: 9;
  width: 100%; /* flex-grow, flex-shrink, flex-basis */
  height: calc(100% - 3rem);
}

.secondBox {
  flex-grow: 1;
}

.hamburgerIcon {
  position: absolute;
  z-index: 10;
  color: var(--text-color);
}

/* Styling the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

/* Optional: Remove scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Styles for the scrollbar track (background) */
::-webkit-scrollbar {
  width: 12px; /* Adjust width */
}

/* Styles for the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid transparent; /* Optional: creates a gap between thumb and track */
  background-clip: content-box; /* Ensures the border doesn't overlap the background color */
}

/* Styling the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color-hoover); /* Color on hover */
}

/* Optional: Remove scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Firefox scrollbar styles */
* {
  scrollbar-color: var(--scrollbar-color); /* Thumb and track color */
}

/* Responsive scaling */
@media (min-width: 768px) {
  .firstBox {
    position: static;
    z-index: 9;
    flex: 0 0 20%;
    height: 100%;
  }
  .box {
    height: calc(100vh - 3.5rem);
  }
}
@media (min-width: 992px) {
}
