.BottomNavBar {
  /* Base styles for navbar */
  bottom: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
}
.BottomNavBarItems {
  display: flex;
  justify-content: space-around; /* Adjusts items to be evenly spaced */
  align-items: center; /* Vertically centers items */
  height: 2.5rem; /* Adjusted for visibility */
  font-size: 0.8rem;
  background-color: var(--chat-win-color);
  color: rgba(14, 139, 217, 1);
  padding: 0; /* Adjust padding as needed */
  width: 100%; /* Ensure the container takes full width */
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.BottomNavBarItem {
  height: 100%;
  flex: 1; /* Allows each item to grow equally */
  display: flex;
  justify-content: center; /* Horizontally centers text */
  align-items: center; /* Vertically centers text */
  text-decoration: none;
  color: inherit; /* Inherits color from parent */
}
.BottomNavBarItem:hover {
  background-color: none;
}

.BottomNavBarItem.active {
  color: var(--text-color); /* Blue color for the active link */
}

@media (min-width: 768px) {
  .BottomNavBarItems {
    height: 3rem; /* Adjusted for visibility */
    font-size: 1.6rem;
  }
  .BottomNavBarItem:hover {
    background-color: var(
      --secondary-background-color
    ); /* Changes background color of the cell */
    color: rgba(80, 180, 243, 1); /* Changes text color */
  }
}
