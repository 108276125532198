/* Responsive scaling - mobile - default*/
.container {
  background-color: var(--background-color) /*#1a1a1d*/;
  padding: 20px;
}

.pageTitle {
  color: var(--text-color) /*white*/;
  text-align: left;
  font-size: 1rem;
  margin-bottom: 10px;
}

.titleLine {
  height: 2px;
  background-color: var(--text-color) /*white*/;
  margin-bottom: 20px;
}

.container2 {
  background-color: var(--tertiary-background-color);
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: auto;
  margin-top: 2em;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.dataContainer {
  display: flex;
  flex-direction: row; /* Or 'column' if you prefer */
  justify-content: flex-start;
  width: 99%;
}

.liContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text {
  display: flex; /* Set to flex to use flexbox */
  justify-content: space-between; /* Aligns children with space between them */
  align-items: center; /* Vertically centers children */
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 1em;
  font-size: 0.9rem;
  color: var(--text-color);
  background-image: var(--color-gradient);
  width: 100%;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.list {
  width: 100%;
  color: var(--text-color);
  cursor: pointer;
}
.expandButton {
  color: var(--text-color);
}

.details {
  font-size: 0.7rem;
}
.details2 .details3 {
  font-size: 0.6rem;
  overflow: hidden; /* Hide the overflow */
  white-space: wrap; /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis; /* Add ellipsis (...) if text overflows */
  max-width: 100%; /* Set a maximum width */
  overflow-x: auto; /* Allow horizontal scrolling */
}
.details3 {
  padding-bottom: 0.6em;
  text-align: left; /* Align text to the left */
  margin-left: 10px;
}
.infoText {
  color: #aa67bb;
  text-align: center;
  font-size: 1.2rem;
}

.addUserButton {
  cursor: pointer;
}

.iconsContainer {
}

.user {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  color: var(--text-color);
  cursor: pointer;
  /* Add some margin if icons are too close to each other */
  margin-left: 10px;
}

.info {
  display: grid;
  grid-template-columns: 170px 1fr 0.5fr auto;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid var(--text-color);
}

.chatbotAvatar {
  width: 5em; /* Adjust as needed */
  height: 5em; /* Adjust as needed */
  object-fit: cover; /* To ensure the image maintains aspect ratio */
}

.chatbot {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  gap: 5%;
  padding-top: 10px;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .container2 {
    width: 100%;
  }
  .details {
    font-size: 1.1rem;
  }
  .details2 {
    font-size: 1.1rem;
  }
  .details3 {
    font-size: 1.1rem;
  }
  .errorMessage {
    font-size: 1rem;
  }
  .text {
    font-size: 1.2rem;
    margin: auto;
    padding-left: 0.5em;
  }
  .pageTitle {
    font-size: 1.3rem;
  }

  .chatbotAvatar {
    width: 4em; /* Adjust as needed */
    height: 4em; /* Adjust as needed */
  }
}
@media (min-width: 992px) {
  .container2 {
    width: 100%;
  }
}
