.container {
  display: flex;
  flex-direction: column;
  color: #404c68;
}
.videoContainer {
  display: flex;
  flex-direction: column;
  color: #404c68;
  gap: 20px;
}

.video {
  width: 350px;
}

.btn {
  background-color: #437eeb;
}

.btn:hover {
  background-color: #404c68;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .video {
    width: 450px;
  }
}
@media (min-width: 992px) {
  .video {
    width: 800px;
  }
}
