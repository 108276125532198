.sliderContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 70px;
}
/* Wrapper to hide overflow */
.sliderWrapper {
  width: 25rem;
  height: 35rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Animation container */
.cardContainer {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out;
}

/* Card styles */
.card {
  width: 25rem;
  height: 35rem;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  border-radius: 10px;
  background-image: var(--card-slider);
  padding: 20px;
  opacity: 1;
}

/* Animations */
.card.entering {
  transform: translateX(100%);
  opacity: 0;
}

.card.exiting {
  transform: translateX(-100%);
  opacity: 0;
}
.prevCard {
  transform: translateX(-6rem) rotate(-12deg);
}

.nextCard {
  transform: translateX(6rem) rotate(12deg);
}

.chevron {
  color: var(--text-color);
  width: 50px;
  height: 50px;
}

/*TITLE CARD*/

.cardCourseTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Add this */
  text-align: center;
}

.cardCourseTitleText {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: bold;
}

.cardCourseTitleImage {
  width: 25rem;
}

/*Description course CARD*/

.cardCoursDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%; /* Add this */
  text-align: justify;
}

.cardCourseDescriptionTitleText {
  margin-top: 0;
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: bold;
  text-align: justify;
}
.cardCourseDescriptionText {
  margin: 0;
  font-size: 1.3rem;
  color: var(--text-color);
}

/*Learning outcomes card*/

.cardCourseLearningOutcomes {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%; /* Add this */
  text-align: justify;
}

.cardCourseLearningOutcomesTitleText {
  margin-top: 0;
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: bold;
  text-align: left;
}
.cardCourseLearningOutcomesText {
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  color: var(--text-color);
}

/*TITLE SM CARD*/

.cardSMTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Add this */
  text-align: center;
}

.cardSMTitleText {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: bold;
}

.cardSMTitleImage {
  width: 25rem;
}
.cardSMTitleImage2 {
  width: 25rem;
}

/*VIDEO SM CARD*/
.cardVideo {
  width: 100%; /* Make the video take the full width of the card */
  height: 100%; /* Make the video take the full height of the card */
  object-fit: cover; /* Ensure the video covers the entire area without distortion */
  border-radius: 10px; /* Maintain the same border-radius as the card */
}

/*Theory card*/

.cardCourseTheory {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%; /* Add this */
  text-align: justify;
}

.cardCourseTheoryTitleText {
  margin-top: 0;
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: bold;
  text-align: left;
}
.cardCourseTheoryText {
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  color: var(--text-color);
}

/*Quiz card*/
.cardQuiz {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardQuizTop {
  display: flex;
  flex-direction: column;
}

.cardQuizQuestionNo {
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: bold;
}

.cardQuizQuestionText {
  font-size: 1.3rem;
  color: var(--text-color);
}

.cardQuizBottom {
  display: flex;
  flex-direction: column;
}

.answerOption {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: var(--card-answer);
  font-size: 1.1rem;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.answerOption:hover {
  background-color: #475569;
}
.correctAnswer {
  background-color: #16a34a;
  color: white;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}
.incorrectAnswer {
  background-color: #dc2626;
  color: white;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}
.feedbackTooltip {
  display: block;
  margin-top: 5px;
  font-size: 0.9rem;
  color: #facc15;
}

/*End of SubModule card*/
.cardCourseEndOfSMimage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 11;
}

.cardCourseEndOfSM {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 12;
}

.cardCourseEndOfSMTitle {
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: bold;
}
.cardCourseEndOfSMText {
  font-size: 1.3rem;
  color: var(--text-color);
}
