.carouselContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-left: 5px; /* Adds space inside the left edge */
  padding-right: 5px; /* Adds space inside the right edge */
}
.cardsContainer {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.button {
  background: transparent; /* Make background transparent */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  padding: 3px; /* Adjust padding to make the button smaller */
  display: flex; /* Use flex to center the image inside the button */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center content horizontally */
}

.button:active {
  transform: scale(0.98); /* press down effect */
}

.img {
  width: auto; /* Keep the image's aspect ratio */
  height: 40px; /* Adjust the image's height to make it smaller */
  display: block; /* Ensure the image is block level to remove any bottom space */
}
.dotsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.dot {
  height: 7px;
  width: 7px;
  margin: 0 3px;
  background-color: rgba(61, 55, 128, 1);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s, width 0.3s, height 0.3s; /* Smooth transition for the active dot */
}

.activeDot {
  width: 10px; /* Make the active dot larger */
  height: 10px; /* Make the active dot larger */
  background-color: rgba(16, 194, 235, 1); /* Highlight the active dot */
}

@media (min-width: 768px) {
  .carouselContainer {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-left: 20px; /* Adds space inside the left edge */
    padding-right: 20px; /* Adds space inside the right edge */
  }

  .button {
    padding: 5px; /* Adjust padding to make the button smaller */
  }

  .img {
    height: 50px; /* Adjust the image's height to make it smaller */
  }
  .dotsContainer {
    padding: 20px;
  }

  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
  }

  .activeDot {
    width: 15px; /* Make the active dot larger */
    height: 15px; /* Make the active dot larger */
  }
}
