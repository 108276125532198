.MainContent {
  /* Space for the top navbar */
  margin-bottom: 3rem; /* Space for the bottom navbar */
  /* Adjust the padding if your content needs some breathing room */
  overflow-y: scroll; /* Enable scrolling within this container if needed */
  height: calc(
    100vh - 5rem
  ); /* Adjust height to take full view height minus navbars */
  /* Ensure this container does not extend under the navbars */
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Cover the entire container */
  background-attachment: scroll; /* Optional: Fixes the background with respect to the viewport */
}

@media (min-width: 768px) {
  .MainContent {
    /* Space for the top navbar */
    margin-bottom: 3rem; /* Space for the bottom navbar */
    height: calc(
      100vh - 6.5rem
    ); /* Adjust height to take full view height minus navbars */
  }
}
