.container {
  background-color: var(--tertiary-background-color); /*#1a1a1d*/
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  animation: fadeIn 1s ease-out;
}

.info {
  display: grid;
  grid-template-columns: 65px 1fr;
  align-items: center;
  margin-bottom: 10px;
}

.userDetails,
.chatbotDetails {
  color: var(--text-color);
  font-size: 0.6rem;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Keep the text in a single line */
  max-width: 100%;
  padding-right: 5px;
}
.chatbotDetails {
  display: flex; /* Use flexbox */
  align-items: center; /* Vertically center the content */
}

.chatbotAvatar {
  width: 3em; /* Adjust as needed */
  height: 3em; /* Adjust as needed */
  object-fit: cover; /* To ensure the image maintains aspect ratio */
}

.chatbot {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
  padding-top: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Responsive scaling */
@media (min-width: 768px) {
  .info {
    grid-template-columns: 100px 1fr;
  }
  .container {
    width: 600px;
  }
  .userDetails,
  .chatbotDetails {
    font-size: 1.1rem;
  }

  .chatbotAvatar {
    width: 4em; /* Adjust as needed */
    height: 4em; /* Adjust as needed */
  }
}
@media (min-width: 992px) {
}
