.container {
  position: relative;
  background-color: var(--secondary-background-color) /*#2020209c*/;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  width: 90%;
  height: 90vh;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.title {
  padding-top: 1em;
  font-size: 1.5rem;
  color: var(--text-color);
}
