/* Responsive scaling - mobile - default*/

.dataContainer {
  display: flex;
  flex-direction: row; /* Or 'column' if you prefer */
  flex-wrap: wrap;
  justify-content: space-around;

  /* Add more styling as needed */
}

.chartContainer {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add more styling as needed */
}

.chartTitle {
  color: var(--text-color);
  text-align: center;
  font-size: 1rem; /* Adjust size as needed */
  margin-bottom: 10px; /* Adjust spacing as needed */
  /* Add more styling as needed */
}

.chart {
  width: 320px;
  height: 270px;
}

.infoText {
  color: var(--text-color-error);
  text-align: center;
  font-size: 0.8rem;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .text {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 1.2rem;
    margin: auto;
  }
  form input {
    width: 700px;
    font-size: 2.2rem;
    padding: 10px;
  }
  form {
    margin-top: 1.7rem;
    margin-bottom: 6rem;
    gap: 2rem;
  }
  form textArea {
    font-size: 2.2rem;
    padding: 10px;
  }
  .avatar img {
    width: 130px; /* Adjust size as needed */
    height: auto;
    display: block;
    margin: auto;
  }
  form button {
    padding: 18px 32px;
    font-weight: bold;
    font-size: 2.2rem;
    border: none;
  }
  .infoText {
    color: var(--text-color-error);
    text-align: center;
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
}
