/* Responsive scaling - mobile - default*/
.dataContainer {
  display: flex;
  flex-direction: row; /* Or 'column' if you prefer */
  justify-content: flex-start;
  width: 99%;
}

.liContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.list {
  width: 100%;
  color: var(--text-color);
  cursor: pointer;
}
.expandButton {
  color: var(--text-color);
}

.details {
  font-size: 1.1rem;
}
.chatbotsContainer {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem;
  padding-bottom: 3em;
  gap: 0.5em;
}

.chatbotsContainer * {
  pointer-events: none; /* Disables clicks for all child elements */
}

.chatbot {
  width: 13em;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(46, 45, 45, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--tertiary-background-color);
  padding-right: 2em;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3em;
  animation: fadeIn 1s ease-out;
  /* Center horizontally */
}

.chatbotAvatar {
  align-self: center;
  width: 5em; /* Adjust as needed */
  height: 5em; /* Adjust as needed */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  border: 5px solid transparent; /* Adjust border width as needed */
  border-radius: 50%; /* If you want rounded avatars */
  background-image: linear-gradient(white, white), var(--color-gradient);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.infoText {
  color: var(--text-color-error);
  text-align: center;
  font-size: 0.8rem;
}
/* Responsive scaling */
@media (min-width: 768px) {
  .infoText {
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
}
