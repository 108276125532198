/* Responsive scaling - mobile - default*/
.dataContainer {
  display: flex;
  flex-direction: row; /* Or 'column' if you prefer */
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  /* Add more styling as needed */
}

.chatbotCard {
  padding-top: 5px;
  text-align: center;
  margin: 10px;
  position: relative;
}

.chatbotAvatar {
  width: 75px; /* Adjust as needed */
  height: 75px; /* Adjust as needed */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  border: 5px solid transparent; /* Adjust border width as needed */
  border-radius: 50%; /* If you want rounded avatars */
  background-image: linear-gradient(white, white), var(--color-gradient);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.chatbotName {
  color: var(--text-color) /*white*/;
  font-size: 1rem;
  margin-top: 5px;
}

.chatbotTitle {
  display: none;
  position: absolute; /* Position the title absolutely relative to the card */
  top: 0; /* Position at the top of the card */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -100%
  ); /* Adjust for exact centering and move above the image */
  background-color: #dee2f0e5; /*#2f2f2fee;*/ /* Semi-transparent background */
  color: black /*white*/; /* Text color */
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap; /* Prevents the text from wrapping */
  z-index: 10; /* Ensure it's above other elements */
}

.chatbotCard:hover .chatbotTitle {
  display: block; /* Show the title on hover */
}

.chatbotCard:hover .chatbotAvatar {
  transform: scale(1.1); /* Optional: Slightly enlarge the avatar on hover */
}

.infoText {
  color: var(--text-color-error);
  text-align: center;
  font-size: 0.8rem;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .dataContainer {
    display: flex;
    gap: 3em;
    /* Add more styling as needed */
  }

  .chatbotCard {
    text-align: center;
    margin: 10px;
    position: relative;
  }

  .chatbotAvatar {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
  }

  .infoText {
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
}
