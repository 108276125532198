/* FRAME: Base styles */
.frame {
  position: relative;
  cursor: pointer;
  background-color: var(--chat-win-color);
  border-radius: 8px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Initially stack children vertically */
  align-items: center; /* Center align items for small screens */
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem; /* Space between tiles */
}

.frame:hover {
  background-color: var(--frame-hover);
}

.squashMenuContainer {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 10; /* Ensure it's above other elements */
}

.frame img {
  height: auto; /* Maintain aspect ratio */
  width: 60px; /* Fixed width for small screens */
  margin-right: 1rem;
}

.frame .content {
  display: flex;
  width: 100%;
  align-items: center;
}

.frame .textContainer {
  display: flex;
  flex-direction: column;
  max-width: calc(
    70% - 1rem
  ); /* Adjust text container width based on image width */
}

.frame .name,
.frame .title,
.frame .description {
  color: var(--text-color);
  white-space: normal;
  overflow-wrap: break-word;
}

.frame .name {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.frame .title {
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}

.frame .description {
  padding-top: 10px;
  font-size: 0.8rem;
  line-height: 1.4;
  height: auto; /* Allow the description to adjust its height */
  overflow: hidden;
}

/* Media queries for larger screens */
@media screen and (min-width: 768px) {
  .frame {
    justify-content: start; /* Align content to the start */
    height: 260px; /* Fixed height */
    width: calc(50% - 20px); /* 2 tiles per row */
  }

  .frame .textContainer {
    max-width: calc(70% - 1rem);
  }

  .frame .title,
  .frame .description {
    font-size: 1.1rem; /* Larger text on wider screens */
  }
  .frame img {
    width: 70px; /* Even larger image on extra large screens */
  }
  .frame .name {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 992px) {
  .frame {
    width: calc(50% - 20px); /* 3 tiles per row */
    height: 260px; /* Adjust the height for very large screens */
  }
  .frame img {
    width: 70px; /* Even larger image on extra large screens */
  }
}

@media screen and (min-width: 1200px) {
  .frame {
    width: calc(33.333% - 20px); /* 4 tiles per row */
    height: 260px; /* Adjust the height for very large screens */
  }
  .frame img {
    width: 90px; /* Even larger image on extra large screens */
  }
}
