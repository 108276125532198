.menu {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center;
  justify-content: flex-start; /* Align items from the left side */
  outline: 2px;
  outline-style: solid;
  outline-color: var(--background-color-active);
  border-radius: 12px;
  top: 0%; /* Adjust as needed */
  right: 110%; /* Aligns the menu to the right of the Squash icon */
  gap: 0.6rem;
  padding: 0.5rem;
  background-color: var(--background-color-input);
}
