.pageBackground {
  width: 100%;
  height: 100%; /* Adjust height as needed */
  overflow: auto;
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Cover the entire container */
  background-attachment: fixed;
}
.container {
  background-color: var(--secondary-background-color) /*#2020209c*/;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.text {
  padding-top: 1em;
  font-size: 1.3rem;
  color: var(--text-color); /*white;*/
}

form {
  width: 90%;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

form input,
select {
  border-radius: 8px;
  border: none;
  font-size: 0.8rem;
  padding: 5px;
  background-color: var(--background-color-input) /*#202020bb*/;
  color: var(--text-color);
}

.selectText {
  font-size: 0.5rem;
}

form textArea {
  background-color: var(--background-color-input);
  color: var(--text-color);
  font-size: 0.8rem;
  padding: 6px;
  /* Ensure it takes full width of its container */
  border: none;
  border-radius: 12px; /* Optional: Rounded corners */
}

.errorMessage {
  margin-top: 2px;
  margin-bottom: 2px;
  color: var(--text-color-error);
  text-align: center;
  font-size: 1.2rem;
}
.avatarContainer {
  display: flex; /* Aligns children in a row */
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Aligns children vertically */
  gap: 10px; /* Optional: Adds space between children */
}

.avatar-selection {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.avatar {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 5px;
  text-align: center;
}

.avatar img {
  width: 80px; /* Adjust size as needed */
  height: auto;
  display: block;
  margin: auto;
}

.avatar.selected {
  border: 1px solid transparent; /* Adjust border width as needed */
  border-radius: 50%; /* If you want rounded avatars */
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgb(179, 36, 215), rgb(0, 142, 206));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.label {
  font-size: 0.8rem;
  color: var(--text-color);
  padding-left: 5px;
}

.chatbotsContainer {
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 8px;
  border: none;
  font-size: 0.8rem;
  padding: 6px;
  background-color: var(--background-color-input);
}

.chatbotItem {
  display: grid;
  grid-template-columns: 70px 1fr 3fr auto; /* Adjust these values as needed */
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.chatbotAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chatbotName,
.chatbotTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  color: var(--text-color);
  font-size: 0.8rem;
}

.chatbotCheckbox {
  justify-self: end;
  padding-left: 20px; /* Aligns the radio button to the right */
  accent-color: rgb(0, 142, 206);
}

.registerChatbotButton {
  background: linear-gradient(
    to right,
    rgb(179, 36, 215) 7.07%,
    rgb(0, 142, 206) 97.81%
  );
  border-radius: 12px;
  color: white;
  padding: 13px 27px;
  font-weight: bold;
  font-size: 0.8rem;
  border: none;
  margin: 10px;
  cursor: pointer;
}
/* Mobile Media Queries */

/* Responsive scaling */
@media (min-width: 768px) {
  .container {
    width: 80%;
  }
  .errorMessage {
    font-size: 2rem;
  }
  .text {
    font-size: 1.8rem;
    margin: auto;
  }
  form input {
    width: 100%;
    font-size: 1.7rem;
    padding: 10px;
  }
  .selectText {
    font-size: 1.4rem;
    padding: 10px;
  }
  form {
    margin-top: 1.7rem;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  form textArea {
    font-size: 1.7rem;
    padding: 10px;
  }
  .avatar img {
    width: 110px; /* Adjust size as needed */
    height: auto;
    display: block;
    margin: auto;
  }
  .label {
    font-size: 1.3rem;
    color: var(--text-color);
    padding-left: 10px;
  }

  .chatbotName,
  .chatbotTitle {
    overflow: hidden; /* Hide the overflow */
    white-space: wrap; /* Prevent text from wrapping to the next line */
    text-overflow: ellipsis; /* Add ellipsis (...) if text overflows */
    color: var(--text-color);
    font-size: 1.5rem;
    max-width: 100%; /* Set a maximum width */
    overflow-x: auto; /* Allow horizontal scrolling */
  }
  .registerChatbotButton {
    padding: 18px 32px;
    font-weight: bold;
    font-size: 1.7rem;
    border: none;
  }
}
@media (min-width: 992px) {
  .container {
    width: 50%;
  }
}
