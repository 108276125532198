.backdrop {
  position: fixed;
  top: 3.5rem; /* Start 3.5rem down from the top */
  left: 0;
  right: 0; /* Stretch to the right edge */
  bottom: 0; /* Stretch to the bottom edge */
  width: 100vw; /* Cover full viewport width */
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.card {
  color: var(--text-color);
  max-width: 70%;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 1s ease;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.card h2 {
  font-size: 0.9rem;
}

.card p {
  font-size: 0.8rem;
}

.chatbotImage {
  background-size: contain; /* Make sure the image fits in the container */
  background-repeat: no-repeat;
  background-position: center;
  height: 4rem; /* Fixed height for the image container */
  width: 100%; /* Image container takes full width of the tile */
  margin-bottom: 10px; /* Space below the image container */
}

/* Responsive scaling */
@media (min-width: 768px) {
  .card h2 {
    font-size: 1.5rem;
  }

  .card p {
    font-size: 1.4rem;
  }
}
@media (min-width: 992px) {
}
