.Container {
  width: 100%;
  color: var(--text-color); /* Ensures at least full viewport height */
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
  padding-top: 150px;
}

.simple_radial {
  flex: 1; /* This will make the div expand to fill available space */
  width: 100%; /* Ensures it takes full width */
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    var(--background-color-gradient) 50%,
    var(--secondary-background-color-gradient) 90%
  );
}
.contentWrapper {
  padding-top: 15%;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align the items */
  gap: 20px; /* Adjust the gap between elements */
  justify-content: center;
  text-align: center;
}

.text {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 15px; /* Adds some padding around the text */
}

.secondaryText {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 15px; /* Adds some padding around the text */
  padding-bottom: 50px;
}
.linkText {
  font-size: 1.2rem;
  padding: 0 20px; /* Adds some padding around the text */
  color: var(--link2);
}

.footer {
  background-color: black;
  color: white; /* Assuming you want white text */

  padding: 20px; /* Adjust padding to your preference */
  width: 100%; /* Ensure it spans the full width of its container */
}

@media (min-width: 768px) {
  .Container {
    width: 100%;
    color: var(--text-color); /* Ensures at least full viewport height */
    display: flex;
    flex-direction: column;
    padding-bottom: 230px;
  }

  .simple_radial {
    flex: 1; /* This will make the div expand to fill available space */
    width: 100%; /* Ensures it takes full width */
    display: flex;
    flex-direction: column;
    background: radial-gradient(
      var(--background-color-gradient) 50%,
      var(--secondary-background-color-gradient) 90%
    );
  }
  .contentWrapper {
    padding-top: 15%;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align the items */
    gap: 20px; /* Adjust the gap between elements */
    justify-content: center;
    text-align: center;
  }

  .text {
    font-size: 2.3rem;
    font-weight: bold;
    padding: 0 20px; /* Adds some padding around the text */
  }

  .secondaryText {
    font-size: 1.9rem;
    font-weight: bold;
    padding: 0 20px; /* Adds some padding around the text */
    padding-bottom: 50px;
  }
  .linkText {
    font-size: 1.9rem;
    padding: 0 20px; /* Adds some padding around the text */
    color: var(--link2);
  }

  .footer {
    background-color: black;
    color: white; /* Assuming you want white text */

    padding: 20px; /* Adjust padding to your preference */
    width: 100%; /* Ensure it spans the full width of its container */
  }

  .buttonContainer {
    /* You can adjust width here if needed */
  }

  /* Styling for GeneralButton can be adjusted within its own CSS if needed */
}
