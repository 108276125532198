.backdrop {
  position: fixed;
  top: 3.5rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.848); /* Dark backdrop */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.error_card {
  color: var(--text-color);
  background-color: var(--tertiary-background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 1s ease;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.error_card h2 {
  margin-top: 0;
}

.return_button {
  background: var(--color-gradient);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none; /* Remove underline from links */
  display: inline-block; /* Allows padding and width/height */
  cursor: pointer;
  margin-top: 20px;
}

.return_button:active {
  transform: scale(0.9); /* Scales down to 90% when clicked */
  transition: transform 0.1s; /* Smooth transition for the effect */
}

.error_animation {
  height: 150px; /* Adjust size as needed */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* Ensure the whole image is visible */

  margin-bottom: 20px;
}

.text {
  font-size: 1rem;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .container {
    width: 100%;
  }

  .text {
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
  .container {
    width: 100%;
  }

  .text {
    font-size: 1.2rem;
  }
}

@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
