.button {
  background: var(--color-gradient);
  border-radius: 12px;
  color: white;
  padding: 13px 27px;
  font-weight: bold;
  font-size: 0.8rem;
  border: none;
  position: relative; /* Needed for the pseudo-element */
  overflow: hidden;
  cursor: pointer;
}

.button:active {
  transform: scale(var(--active-scale));
}

.button:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    /* Shiny part */ rgba(255, 255, 255, 0) 100%
  );
  animation: shine 1.5s ease infinite;
  background-size: 200% 200%; /* Enlarge background */
}

@keyframes shine {
  from {
    background-position: top right; /* Start from the bottom-left corner */
  }
  to {
    background-position: bottom left; /* Move to the top-right corner */
  }
}

/* Responsive scaling */
@media (min-width: 768px) {
  .button {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 992px) {
  /* Adjustments for larger screens can be added here if needed */
}

@media screen and (min-width: 1200px) {
  /* Adjustments for extra large screens can be added here if needed */
}
