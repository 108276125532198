.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.title {
  font-size: 24px;
  color: white;
}

.inputContainer {
  width: 60%;
  height: 400px;
  overflow-y: scroll;
}
