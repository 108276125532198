.container {
  position: relative;
  height: 100px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: default;
  border: 2px solid var(--text-color-error);
  background-color: var(--chat-win-color);
}

.text {
  margin: 0px;
  padding: 3px;
  font-size: 13px;
  color: var(--text-color-error);
}

.icon {
  color: var(--text-color-error);
  font-size: 24px;
}

.floatingIcon {
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99999;
}

.picture {
  border-radius: 12px;
  max-width: 150px;
  max-height: 90px;
}
