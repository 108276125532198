.send {
  align-items: flex-start;
  background-color: var(--send-button); /*black*/
  border-radius: 360px;
  display: inline-flex;
  gap: 8px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
}
.send:active {
  transform: scale(0.98); /* press down effect */
}

.send .img {
  height: 24px;
  position: relative;
  width: 24px;
}
.disabled {
  opacity: 0.7; /* Example: makes the button look faded when disabled */
  cursor: not-allowed; /* Changes the cursor to indicate it's not clickable */
}
