.pageBackground {
  width: 100%;
  height: 100%; /* Adjust height as needed */
  overflow: auto;
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Cover the entire container */
  background-attachment: fixed;
}
.container {
  background-color: var(--secondary-background-color) /*#2020209c*/;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.text {
  padding-top: 1em;
  font-size: 1.3rem;
  color: var(--text-color); /*white;*/
}

.checkboxBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxText {
  font-size: 0.8rem;
  color: var(--text-color); /*white;*/
}

.checkbox {
  font-size: 0.2rem;
  width: 10%;
  height: 10%;
}

form {
  width: 90%;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

form input {
  border-radius: 8px;
  border: none;
  font-size: 0.8rem;
  padding: 6px;
  background-color: var(--background-color-input) /*#202020bb*/;
  color: var(--text-color);
}

form textArea {
  background-color: var(--background-color-input);
  color: var(--text-color);
  font-size: 0.8rem;
  padding: 6px;
  /* Ensure it takes full width of its container */
  border: none;
  border-radius: 12px; /* Optional: Rounded corners */
}

.errorMessage {
  color: var(--text-color-error);
  text-align: center;
  font-size: 1.2rem;
}
.avatarContainer {
  width: 90%;
  display: flex; /* Aligns children in a row */
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Aligns children vertically */
  gap: 10px; /* Optional: Adds space between children */
  flex-wrap: wrap;
}

.avatar-selection {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.avatar {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 5px;
  text-align: center;
}

.avatar img {
  width: 50px; /* Adjust size as needed */
  height: auto;
  display: block;
  margin: auto;
}

.avatar.selected {
  border: 1px solid transparent; /* Adjust border width as needed */
  border-radius: 50%; /* If you want rounded avatars */
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgb(179, 36, 215), rgb(0, 142, 206));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

/* Mobile Media Queries */

/* Responsive scaling */
@media (min-width: 768px) {
  .container {
    width: 80%;
  }
  .errorMessage {
    font-size: 2.2rem;
  }
  .text {
    font-size: 1.8rem;
    margin: auto;
  }
  form input {
    width: 100%;
    font-size: 1.7rem;
    padding: 10px;
  }
  form {
    margin-top: 1.7rem;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  form textArea {
    font-size: 1.7rem;
    padding: 10px;
  }
  .avatar img {
    width: 70px; /* Adjust size as needed */
    height: auto;
    display: block;
    margin: auto;
  }

  .checkboxBox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkboxText {
    font-size: 1.3rem;
    color: var(--text-color); /*white;*/
  }

  .checkbox {
    font-size: 0.5rem;
    width: 10%;
    height: 10%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 50%;
  }
}
