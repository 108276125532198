.card {
  background-color: var(--secondary-background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 10px;
  box-shadow: 0 4px 8px rgb(70, 49, 79);
  transition: width 0.5s; /* Add transition for smoother size changes */
  opacity: 0.5; /* Start cards as invisible */
  transition: opacity 0.5s, transform 0.5s;
  height: 32rem;
  width: 34rem;
  box-sizing: border-box;
}

.cardImage {
  width: 100%; /* Adjust this as necessary, could be less than 100% if needed */
  max-width: 100%; /* This ensures the image won't exceed the card's width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px;
  margin-bottom: 7px;
  box-shadow: 0 4px 8px rgb(51, 50, 54);
}

.card,
.cardImage {
  box-sizing: border-box;
}

.cardTitle {
  padding-top: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}

.cardText {
  line-height: 1.4rem;
  padding-left: 1rem;
  font-size: 1rem;
}

/* Additional styles for active and smaller cards */

.card.active {
  opacity: 1; /* Active card is fully visible */
  transform: translateX(0); /* Active card is centered */
  width: 100%;
  overflow-y: scroll;
}

.card.small {
  display: none;
}

.cardImageSmall {
  width: 70%;
  max-width: 150px; /* Adjust as necessary */
  border-radius: 4px;
  margin-bottom: 7px;
  box-shadow: 0 4px 8px rgb(51, 50, 54);
}

.cardTitleSmall {
  padding-top: 5px;
  font-size: 0.9rem;
  font-weight: bold;
}

.cardTextSmall {
  line-height: 1.2rem;
  padding-left: 1rem;
  font-size: 0.8rem;
}
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .card {
    background-color: var(--secondary-background-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin: 10px;
    box-shadow: 0 4px 8px rgb(70, 49, 79);
    transition: width 0.5s; /* Add transition for smoother size changes */
    opacity: 0.5; /* Start cards as invisible */
    transition: opacity 0.5s, transform 0.5s;
    height: 32rem;
    width: 34rem;
  }

  .cardImage {
    width: 100%;
    max-width: 290px; /* Adjust as necessary */
    border-radius: 4px;
    margin-bottom: 7px;
    box-shadow: 0 4px 8px rgb(51, 50, 54);
  }

  .cardTitle {
    padding-top: 5px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .cardText {
    line-height: 2rem;
    padding-left: 1rem;
    font-size: 1.1rem;
  }

  /* Additional styles for active and smaller cards */

  .card.active {
    opacity: 1; /* Active card is fully visible */
    transform: translateX(0); /* Active card is centered */
    width: 100%;
    overflow-y: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    width: 600px;
  }

  .card.small {
    display: block;
    width: 22.5rem; /* Smaller width */
    height: 29rem; /* Smaller height */
    opacity: 0.7; /* Side cards are partially visible */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cardImageSmall {
    width: 70%;
    max-width: 150px; /* Adjust as necessary */
    border-radius: 4px;
    margin-bottom: 7px;
    box-shadow: 0 4px 8px rgb(51, 50, 54);
  }

  .cardTitleSmall {
    padding-top: 5px;
    font-size: 1rem;
    font-weight: bold;
  }

  .cardTextSmall {
    line-height: 1.7rem;
    padding-left: 1rem;
    font-size: 0.9rem;
  }
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
