/* Responsive scaling - mobile - default*/

.chartContainer {
  width: 300px;
  height: 300px;
  padding-left: 20px;
  /* Add more styling as needed */
}

.infoText {
  color: var(--text-color-error);
  text-align: center;
  font-size: 0.8rem;
}

/* Responsive scaling */
@media (min-width: 768px) {
  .chartContainer {
    width: 900px;
    height: 500px;
    /* Add more styling as needed */
  }
  .infoText {
    color: var(--text-color-error);
    text-align: center;
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
}
