/* VoiceRecorder.module.css */
.customMainContainer {
  background-color: var(--tertiary-background-color); /*#2020209c;*/
  padding: 10px;
  border-radius: 8px;
}

.customCanvasContainer {
  border-radius: 8px;
  background-color: var(--tertiary-background-color); /*#2020209c;*/
  width: 100%;
}

.customProgressIndicator {
  background-color: #291674;
}

.customButton {
  background: linear-gradient(
    to right,
    rgb(179, 36, 215) 7.07%,
    rgb(0, 142, 206) 97.81%
  );
  border-radius: 12px;
  color: white;
  padding: 10px 15px;
  margin: 2px;
  cursor: pointer;
}

.customButton:hover {
  background-color: #45a049;
}

.audioInfoContainer {
  height: 'auto';
  gap: '0px';
}

.microphone {
  color: white;
  font-size: 24px;
  margin: 10px;
  cursor: pointer;
  color: var(--audio-controls);
}

.controlsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timer {
  color: var(--text-color);
  font-size: 1.2rem;
}
